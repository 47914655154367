// require('./bootstrap')

window.Vue = require('vue')

require('./components')

let marked = require('marked')

Vue.mixin({
  methods: {
    marked: value => {
      return marked(value, { sanitize: true })
    }
  }
})

const app = new Vue({
    el: '#app'
})
