<template>
  <div class="my-12">
    <div class="md:flex items-center justify-start md:border-b md:border-primary-lighter md:mb-2 md:pb-2">
      <h3 class="font-serif mr-4">Hotels</h3>
      <div class="md:flex" v-if="shows.length > 1">
        <template
          v-for="(show, index) in shows">
          <div
            @click="activeShow = index"
            :class="[activeShow === index ? 'bg-blue' : 'bg-primary hover:bg-primary-darker', index === 0 ? 'rounded-t md:rounded-t-none md:rounded-l' : '', index === shows.length - 1 ? 'rounded-b md:rounded-b-none md:rounded-r' : '']"
            class="cursor-pointer py-1 px-2 text-center text-primary-lightest">{{ show.title }}</div>
        </template>
      </div>
    </div>
    <div class="md:flex flex-wrap items-stretch md:-mx-4">
      <template v-for="hotel in shows[activeShow].hotels">
        <div class="md:w-1/2 md:px-4 my-4">
          <div class="bg-primary text-primary-lightest rounded-t p-2 shadow">{{ hotel.name }}</div>
          <div class="bg-primary-lightest rounded-b p-4 shadow">
            <div class="flex justify-between mb-2">
              <div class="leading-tight">
                <address class="roman">
                  <strong class="text-semi block">{{ hotel.street_address }}</strong>
                  {{ hotel.city }}, {{ hotel.state }} {{ hotel.zip_code }}
                </address>
                <span class="block">{{ hotel.phone }}</span>
                <span class="block" v-if="hotel.fax">Fax: {{ hotel.fax }}</span>
              </div>
              <div
                v-if="hotel.pricing"
                v-html="marked(hotel.pricing)"
                class="w-1/2 text-right">
              </div>
            </div>
            <div
              v-if="hotel.notes"
              v-html="marked(hotel.notes)">
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['shows'],
    data () {
      return {
        activeShow: 0
      }
    }
  }
</script>
